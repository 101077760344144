var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.dataTableLoading,"no-data-text":_vm.$t('dataTable.NO_DATA'),"no-results-text":_vm.$t('dataTable.NO_RESULTS'),"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"items-per-page":5,"server-items-length":_vm.totalItems,"footer-props":{
      'items-per-page-text': _vm.$t('dataTable.ROWS_PER_PAGE'),
      'items-per-page-options': [5, 10, 25]
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"mt-3 pl-4",attrs:{"cols":"12","sm":"12","md":"4"}},[_c('div',{staticClass:"text-left"},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('cities.TITLE')))])],1)]),_c('v-col',{staticClass:"px-3",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"id":"search","append-icon":"mdi-magnify","label":_vm.$t('dataTable.SEARCH'),"single-line":"","hide-details":"","clearable":"","clear-icon":"mdi-close"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"mb-2 mt-2 pr-2",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.submit()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var invalid = ref.invalid;
return [_c('v-dialog',{attrs:{"max-width":"500px","content-class":"dlgNewEditItem"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('div',{staticClass:"text-right"},[_c('v-btn',_vm._g({staticClass:"btnNewItem",attrs:{"color":"secondary"}},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t('dataTable.NEW_ITEM'))+" ")],1)],1)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[(_vm.editedItem._id)?[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('label',{attrs:{"for":"createdAt"}},[_vm._v(_vm._s(_vm.$t('common.CREATED')))]),_c('div',{attrs:{"name":"createdAt"}},[_vm._v(" "+_vm._s(_vm.getFormat(_vm.editedItem.createdAt))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('label',{attrs:{"for":"updatedAt"}},[_vm._v(_vm._s(_vm.$t('common.UPDATED')))]),_c('div',{attrs:{"name":"updatedAt"}},[_vm._v(" "+_vm._s(_vm.getFormat(_vm.editedItem.updatedAt))+" ")])])]:_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"name","required":"","name":"name","label":_vm.$t('cities.headers.NAME'),"error":errors.length > 0,"error-messages":errors[0],"autocomplete":"off"},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})]}}],null,true)})],1)],2)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"btnCancel",attrs:{"color":"red lighten3","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('dataTable.CANCEL'))+" ")]),_c('v-btn',{staticClass:"btnSave",attrs:{"color":"green lighten3","text":"","disabled":invalid},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('dataTable.SAVE'))+" ")])],1)],1)],1)]}}])})],1)],1)]},proxy:true},{key:"item._id",fn:function(ref){
    var item = ref.item;
return [_c('v-row',{staticClass:"justify-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"edit","icon":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('dataTable.EDIT')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"delete","icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('dataTable.DELETE')))])])],1)]}},{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormat(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormat(item.updatedAt))+" ")]}},{key:"footer.page-text",fn:function(ref){
    var pageStart = ref.pageStart;
    var pageStop = ref.pageStop;
    var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" "+_vm._s(_vm.$t('dataTable.OF'))+" "+_vm._s(itemsLength)+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('dataTable.NO_DATA'))+" ")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('dataTable.NO_RESULTS'))+" ")]},proxy:true}],null,true)}),_c('ErrorMessage'),_c('SuccessMessage')],1)}
var staticRenderFns = []

export { render, staticRenderFns }